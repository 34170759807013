.l-section {
  min-height: 20vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;

  h1 {
    max-width: 450px;

    @media #{$large-screen} {
      max-width: 600px;
    }

    @media #{$medium-screen} {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  h2, p, ul {
    max-width: 360px;

    @media #{$large-screen} {
      max-width: 500px;
    }

    @media #{$medium-screen} {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &-left {
    padding-right: 1rem;

    @media #{$small-screen} {
      padding-right: 0;
    }
  }

  &-right {
    padding-left: 1rem;

    @media #{$small-screen} {
      padding-left: 0;
    }
  }

  &-content {
    margin: 0 auto;
    max-width: $max-width;
    padding: 4.5rem 0;
    width: 90%;

    @media #{$large-screen} {
      max-width: 1200px;
    }

    @media #{$small-screen} {
      padding: 2rem 0;
    }

    .l-section-hero & {
      padding-top: 4rem;
      padding-bottom: 3rem;

      @media #{$small-screen} {
        padding: 2rem 0;
      }

    }
  }

  &-white-bg {
    background: #fff;
  }

  &-grey-bg {
    background: $light-grey;
  }

  &-dark-bg {
    background: $grey;
    color: #fff;
  }

  &-green-bg {
    background: $green;
    color: #fff;

    h2 {
      color: #fff;
    }
  }

  &-hero {
    background: $green;
    color: #fff;
    padding: 0 0 2rem 0;
    min-height: 30rem;

    @media #{$medium-screen} {
      min-height: 0;
    }

    &-left {

      @media #{$medium-screen} {
        h2, p, a {
          width: 50vw;
        }
      }

      // @media #{$small-screen} {
      //   order: 2;
      // }
    }

    &-left-signup {
      margin-top: 4rem;
      p {
        font-size: .75rem;
      }

      @media #{$small-screen} {
        margin-top: 2rem;
      }
    }

    &-image {
      margin-right: -4rem;
    }

    &-right {
      margin-right: -10rem;
      transform: translateY(5rem) scale(.8);
      transition-duration: 1s !important;
      transition-timing-function: cubic-bezier(.5,-.5,0,1.4) !important;

      @media #{$large-screen} {
        margin-right: -12rem;
      }

      @media #{$small-screen} {
        //order: 1;
        margin-top: 2rem;
      }

      &-video {
        width:800px;
        margin-right: -4rem;
        padding-top:24px;
        background-image: url(/images/toolbar-image.png);
        background-repeat: no-repeat;
        background-size: 100% auto;
        line-height: 0;

        @media #{$large-screen} {
          padding-top:32px;
          width: 1000px;
        }

        @media #{$medium-screen} {
          margin-left: 5vw;
        }

        @media #{$small-screen} {
          padding-top:4%;
          width: 120vw;
        }
      }


    }
  }

  &-section-2 {
    background: $grey;
    color: #fff;
    padding: 2rem 0;

    &-top-left {
      
    }

    &-top-right {
      img {
        margin-top: -4rem;
        margin-bottom: -4rem;
        margin-right: -2rem;
        margin-left: -6rem;
      }

      @media #{$medium-screen} {
        img {
          margin: 0 -10rem 0 0;
          width: 60vw;
        }
      }

      @media #{$small-screen} {
        img {
          margin-top: 2rem;
          margin-left: -5rem;
          margin-bottom: -2rem;
          height: 400px;
          width: auto;
        }
      }
    }

    .l-section-content {
      padding-bottom: 0;
    }
  }

  &-center-quote {
    flex: 1;
    font-size: 18px;
    margin: 0 auto;
    max-width: 400px;
    width: 90%;

    @media #{$small-screen} {
      font-size: .75rem;
      max-width: 80%;
    }
  }

  &-section-3 {
    background: #fff;
    position: relative;
    overflow: hidden;

    &:before {
      background: $grey;
      content: "";
      height: 40rem;
      position: absolute;
        top: -20rem;
        right: -150%;
        left: -150%;
      transform: rotateZ(15deg);
      z-index: 1;

      @media #{$small-screen} {
        height: 30rem;
      }
    }

    &-content {
      z-index: 10;
      position: relative;
    }

    &-image {
      background: url(/images/interplayapp-design-with-code.jpg) no-repeat;
      background-size: cover;
      display: block;
      margin: 0 auto;
      height: auto;
      padding-top: 62.25%;
      max-width: 1017px;

      @media #{$large-screen} {
        max-width: 2000px;
      }

      @media #{$small-screen} {
        height: 300px;
        width: 480px;
        margin: 0 auto 2rem;
      }
    }

  }


  &-section-5 {
    min-height: 40rem;

    @media #{$large-screen} {
      min-height: 32rem;
    }

    @media #{$small-screen} {
      .l-section-left {
        order: 2;
      }

      .l-section-right {
        order: 0;
      }
    }

    &-image {
      background: url(/images/placeholders/image-5.png) no-repeat center;
      background-size: cover;
      position: absolute;
        left: 0;
      height: 557px;
      width: 498px;

      @media #{$small-screen} {
        display: block;
        position: relative;
        margin: 2rem auto;
        height: 278px;
        width: 249px;
      }
    }
  }


  &-section-6 {
    min-height: 43rem;

    @media #{$large-screen} {
      min-height: 34rem;
    }

    &-image {
      background: url(/images/placeholders/image-6.png) no-repeat center;
      background-size: cover;
      position: absolute;
        left: 0;
      height: 597px;
      width: 357px;
      max-width: 100%;

      @media #{$small-screen} {
        display: block;
        position: relative;
        margin: 2rem auto;
        height: 335px;
        width: 200px;
      }
    }
  }

  &-section-7 {

    @media #{$small-screen} {
      .l-section-left {
        order: 2;
      }

      .l-section-right {
        order: 0;
      }
    }

    &-image {
      background: url(/images/design-system-user-testing.jpg) no-repeat center;
      background-size: cover;
      position: absolute;
        left: 0;
      height: 482px;
      width: 597px;

      @media #{$small-screen} {
        display: block;
        position: relative;
        margin: 2rem auto;
        height: 241px;
        width: 300px;
      }

    }

  }

  &-signup {
    
    input[type=email] {
      background: none;
      border: none;
      border-bottom: 1px solid $black;
      color: $black;
      font-size: 20px;
      padding: .25rem 0;
      //margin-bottom: 1rem;
      //margin-bottom: 0.25rem;
      width: 100%;
      max-width: 340px;

      @media #{$medium-screen-down} {
        max-width: 50vw;
      }

      &:focus {
        outline: none;
      }
    }

    ::-webkit-input-placeholder {
      color: $black;
      opacity: 1;
    }

    .l-section-left {
      h2 {
        margin-left: 0;
      }
    }

    &-image {
      background: url(/images/logo-loop.png) no-repeat;
      background-size: cover;
      position: absolute;
      right: 0;
      bottom: 0;
      margin-bottom: -5rem;
      height: 297px;
      width: 297px;

      @media #{$small-screen} {
        height: 128px;
        width: 128px;
        right: -1rem;
        margin-bottom: -2.5rem;
      }
    }
  }
}

.l-policy-page {
  p {
    max-width: 1000px;
  }
}