/* Flexbox grid system from SolvedByFlexbox */
.grid {
  display: flex;

  @media #{$small-screen} {
    flex-wrap: wrap;
  }
}

.grid-cell {
  flex: 1;
  position: relative;

  @media #{$small-screen} {
    flex: none;
    width: 100%;
  }
}

/* With gutters */
.grid--gutters {
  margin: -1em 0 0 -1em;
}
.grid--gutters > .grid-cell {
  padding: 1em 0 0 1em;
}

/* Alignment per row */
.grid--top {
  align-items: flex-start;
}
.grid--bottom {
  align-items: flex-end;
}
.grid--center {
  align-items: center;
}

/* Alignment per cell */
.grid-cell--top {
  align-self: flex-start;
}
.grid-cell--bottom {
  align-self: flex-end;
}
.grid-cell--center {
  align-self: center;
}

/* Base classes for all media */
.grid--fit > .grid-cell {
  flex: 1;
}
.grid--full > .grid-cell {
  flex: 0 0 100%;
}
.grid--1of2 > .grid-cell {
  flex: 0 0 50%
}
.grid--1of3 > .grid-cell {
  flex: 0 0 33.3333%
}
.grid--1of4 > .grid-cell {
  flex: 0 0 25%
}
