
html, body {
  color: $grey;
  font-family: $primary-font;
  font-size: 20px;
  line-height: 1.75;

  @media #{$large-screen} {
    font-size: 24px;
  }
}

* {
  -webkit-font-smoothing: antialiased;
}

h1 {
  color: $grey;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.33;
}

h2 {
  color: $green;
  font-size: 32px;
  font-weight: normal;
  line-height: 1.5;
  margin-top: 1rem;
}

p {
  
}

a {
  color: $green;
  font-weight: bold;
  text-decoration: none;
}

.consent a {
  color: $grey;
  font-weight: normal;
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.text-footnote {
  font-size: .75rem;
}

.text-error {
  font-size: .75rem;
  color: rgba(255, 255, 255, .8);
  margin: -.5rem 0 1rem;
}

input.green-autofill:-webkit-autofill {
   /* -webkit-box-shadow: 0 0 0 30px $green inset; */
}

input.checkbox {
  margin-bottom: 1rem;
}
// input.checkbox {
//   -webkit-appearance: none;
// 	background-color: #fafafa;
// 	border: 1px solid #cacece;
// 	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
// 	padding: 9px;
// 	border-radius: 3px;
// 	display: inline-block;
// 	position: relative;
// }

// input.checkbox:active, input.checkbox:checked:active {
// 	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
// }

// input.checkbox:checked {
// 	background-color: #e9ecee;
// 	border: 1px solid #adb8c0;
// 	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
// 	color: #99a1a7;
// }

span.consent {
  font-size: .75rem;
  margin-left: 5px;
  color: #1D1E20;
}