.l-footer {
  background: $grey;
  bottom: 0;
  color: $light-grey;
  font-size: 16px;
  padding: 4rem 0;
  position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
  z-index: 0;

  @media #{$small-screen} {
    position: relative;
  }

  .grid {
    @media #{$medium-screen-down} {
      flex-wrap: wrap;
    }
  }

  .grid-cell {
    @media #{$medium-screen-down} {
      flex: none;
      width: 100%;
    }
  }

  @media #{$medium-screen-down} {
    padding: 2rem 0;
  }

  &-content {
    padding-bottom: 2rem;
    max-width: $max-width;
    margin: 0 auto;
    width: 90%;
  }

  &-base-content {
    max-width: $max-width;
    margin: 0 auto;
    width: 90%;
  }

  &-base {
    background: $black;
    color: darken($light-grey, 50%);
    padding: 1rem 0;
    position: absolute;
      right: 0;
      bottom: 0;
      left: 0;

    p {
      font-size: .75rem;
      margin: 0;

      @media #{$medium-screen-down} {
        font-size: .5rem;
        padding-left: .5rem;
      }
    }
  }

  ul, li {
    list-style: none;
    margin: 0;
  }

  ul {
    padding: 0;

    @media #{$medium-screen-down} {
      padding: 1rem .5rem;
    }
  }

  &-link-header {
    padding-bottom: .15rem;
  }

  &-link,
  &-text {
    padding: .15rem 0;
  }

  &-link a {
    color: darken($light-grey, 50%);
    text-decoration: none;
    font-weight: normal;

    &:hover {
      color: $light-grey;
    }
  }

  &-text {
    color: darken($light-grey, 50%);
  }
}