.c-header {
  padding: 1rem;
  background: $green;

  &-nav {
    font-size: 16px;
    position: absolute;
      top: 1rem;
      right: 1rem;

    .sticky-header & {
      top: .75rem;
    }

    @media #{$small-screen} {
      top: .5rem;
      right: .5rem;

      .sticky-header & {
        top: .5rem;
      }
    }

    a {
      color: $grey;
      line-height: 50px;
    }
  }

  @media #{$small-screen} {
    font-size: 12px;
  }

  .sticky-header & {
    position: fixed;
      top: 0;
      left: 0;
      right: 0;
    background: $grey;
    transform: translateY(0);
    transition: transform 1s cubic-bezier(0,1,.3,1);
    z-index: 9;

    a {
      color: #fff;
    }

    .c-button {
      background: $green;
    }
  }

  .sticky-header-hide & {
    transform: translateY(-100%);
  }

  .no-transition & {
    transition: transform 0s;
  }


}