.c-button {
  color: #fff !important;
  background: $grey;
  border: 1px solid transparent;
  font-size: 16px;
  padding: .5rem 1rem;
  margin: 0 1rem;

  @media #{$small-screen} {
    margin-right: 0;
  }

  &.no-margin {
    margin: 0;
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: 0 0 .5rem #fff;
  }


}