.c-features-list {
  &-item {
      list-style: none;

    &:before {
      content: "→";
      position: absolute;
      transform: translateX(-30px);
    }
  }
}