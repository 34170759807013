
.c-logo {
  background: url(/images/top-logo.png) no-repeat;
  background-size: cover;
  display: block;
  height: 40px;
  width: 180px;

  .l-footer &,
  .sticky-header & {
    background-image: url(/images/footer-logo.png);
  }

  @media #{$small-screen} {
    height: 30px;
    width: 135px;
  }
}