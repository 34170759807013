/* Fonts */
$primary-font: 'Source Sans Pro', Helvetica, sans-serif;

/* Colours */
$green: #35C38C;
$grey: #333333;
$light-grey: #EBEBEB;
$black: #1D1E20;

/* Dimensions */
$max-width: 1000px;

/* Breakpoints */
$large-screen: "(min-width: 1200px)";
$medium-screen: "(min-width: 580px) and (max-width: 980px)";
$medium-screen-down: "(max-width: 980px)";
$small-screen: "(max-width: 580px)";