/* General styling that applies globally */
*, *:before, *:after {
  box-sizing: border-box;
}

html, body {
  background-color: $black;
  transition: opacity .6s ease-out;
}

body.hide-all {

  > * {
    display:none;
  }

  .c-typeform-close {
    display:block;
  }
}
@media #{$small-screen} {
  body {
    margin-bottom: 0 !important;
  }
}

.has-shadow {
  box-shadow: 0 .5rem 4rem rgba(0,0,0,.4);

  @media #{$small-screen} {
    box-shadow: 0 .25rem 2rem rgba(0,0,0,.1) !important;
  }
}

/* Showing the content when ready */
.js-loading {
  opacity: 0;
}

[data-aos="fade-up"] {
  transition-timing-function: cubic-bezier(0,.8,0,1) !important;
}