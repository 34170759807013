.c-typeform {
  display: none;
  position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  z-index: 10;

  &.display {
    background: #fff;
    display: block;
  }

  &-iframe-container {
    position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

  }

  iframe {
    height: 100%;
    width: 100%;
  }

  &-close {
    background: #fff;
    border-radius: 50%;
    color: $light-grey;
    cursor: pointer;
    font-size: 32px;
    font-weight: bold;
    height: 50px;
    line-height: 42px;
    position: fixed;
    top: 1rem;
    right: 1rem;
    border:solid 1px #FAFAFA;
    text-align: center;
    width: 50px;
    z-index: 200;

    &.is-hidden {
      display: none;
    }
  }
}